#home {
  h1 {
    font-family: "Fredoka One", cursive;
    font-size: 3rem;
  }
}
/* Tab Container */
.Tabs {
  width: 75%;
  height: auto;
  min-height: 550px;
//   background: red;
  margin: 3.5rem auto 1.5rem;
  color: #e8f0f2;
  border-radius: 2rem;
}
.workingContent{
    width: 60%;
    margin: auto;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  width: 40%;
  margin: 0 auto 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 2rem;
  padding-left: 0px;
}

.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  margin: 0;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 1.2rem;
}

.react-tabs__tab--selected {
  background: rgb(255, 102, 196);
  outline: none;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}


.react-tabs__tab:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.react-tabs__tab:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-size: 3rem;
  text-align: center;
}
@media screen and (max-width: 500px) {
    .Tabs {
        width: 75%;
        height: auto;
        min-height: 550px;
      //   background: red;
        margin: 3.5rem auto 1.5rem;
        color: #e8f0f2;
        border-radius: 2rem;
      }
    .react-tabs__tab-list {
        width: 90%;
        margin: 0 auto 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid white;
        border-radius: 2rem;
        padding-left: 0px;
      }
      .react-tabs__tab {
        width: 100%;
        padding: 1rem;
        margin: 0;
        list-style: none;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
        font-size: 1.2rem;
      }
      .react-tabs__tab:first-child {
        border-bottom-left-radius: 0rem;
        border-top-right-radius: 2rem;
      }
      
      .react-tabs__tab:last-child {
        border-top-right-radius: 0rem;
        border-bottom-left-radius: 2rem;
      }
}
